"use strict";

async function importComponents() {
  const { default: React } = await import(
    /* webpackChunkName: "react" */ "react"
  );
  const { default: ReactDOM } = await import(
    /* webpackChunkName: "react" */ "react-dom"
  );
  const { default: NewsletterSignup } = await import(
    /* webpackChunkName: "sales" */ "./components/NewsletterSignup"
  );

  return { React, ReactDOM, NewsletterSignup };
}

importComponents().then(({ React, ReactDOM, NewsletterSignup }) => {
  const domContainer = document.querySelector("#newsletter-signup-container");
  ReactDOM.render(
    React.createElement(NewsletterSignup, domContainer.dataset),
    domContainer
  );
});
